<template>
  <div class="joystick-container">
    <div 
      ref="joystick"
      class="joystick"
      :style="style"
      :class="isCircular ? 'is-circular' : 'not-circular'"
      @touchmove="joystickHandleTouch"
      @touchstart="joystickHandleStart"
      @touchend="handleRelease"
    >
      <div class="joystick-center" v-if="isCircular" :style="{ backgroundColor: 'var(--centerColor)' }"></div>

      <div class="joystick-ball"
        @touchmove="handleTouch"
        @mousemove="handleMove"
        @touchstart="handleStart"
        @mousedown="handleStart"
        @mouseup="handleRelease"
        @touchend="handleRelease"
        :style="{ backgroundColor: 'var(--userColor)' }">
      </div>
      
      <div class="joystick-stick" v-if="!isCircular"></div>
      
      <div v-if="debug" class="debugger">
        {{this.angle}}°<br>
        {{this.buttons}}
      </div>
    </div>
    <div class="jButtons" v-if="buttons > 1">
      <div v-for="n in buttons"
        :key="`btn-${n}`"
        class="button"
        @touchstart="buttonChange(n,true)"
        @touchend="buttonChange(n,false)">
        {{['A','B','C'][n-1]}}
      </div>
    </div>
  </div>
</template>


<script>
// TODO: XY normalizar 
export default {
  props: {
    centerColor: {
      type: String,
      default: '#fafafa'
    },
    isCircular: {
      type: Boolean,
      default: true
    },
    debug: {
      type: Boolean,
      default: false
    },
    buttons: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      x: 0,
      y: 0,
      angle: 180,
      speed: 0,
      radians: 0,
      size: 240,
      halfSize: 120,
      offset: 0,
      isMouseDown: false,
    };
  },

  computed: {
    //Para pasar variables al css, deben asignarse en el estilo inline 
    style() {
      return {
        "--size": this.isCircular ? '21%' : '40%',
        "--speed": `${this.speed}px`,
        "--offcenter": this.isCircular ? '-208%' : `${this.speed}px`,
        "--angle": this.isCircular ? `calc(${this.angle}deg + 90deg)` : `calc(${this.angle}deg - 90deg)`,
        '--centerColor': this.centerColor
      };
    }
  },

  methods: {
    handleStart() {
      // console.log("Mouse/Touch Down")
      this.$emit("touchStart")
      this.isMouseDown = true;
    },
    joystickHandleStart({ touches: [touch] }) {
      // console.log("Mouse/Touch Down")
      this.$emit("touchStart")
      this.isMouseDown = true;
      this.joystickHandleTouch({ touches: [touch] })
    },
    joystickHandleTouch({ touches: [touch] }) {
      if(!this.isMouseDown) return
      if(touch.target.className == "joystick-center") { return}
      // console.log(touch)
      const { clientX, clientY } = touch;
      const { offsetLeft, offsetTop } = this.$el;
      const x = Math.round(clientX - offsetLeft - this.halfSize);
      const y = Math.round(clientY - offsetTop - this.halfSize);
      this.updatePosition(x, y);
    },
    handleTouch({ touches: [touch] }) {
      const { clientX, clientY } = touch;
      const { offsetLeft, offsetTop } = this.$el;
      const x = Math.round(clientX - offsetLeft - this.halfSize);
      const y = Math.round(clientY - offsetTop - this.halfSize);
      this.updatePosition(x, y);
    },
    handleMove({ clientX, clientY }) {
      if (!this.isMouseDown) return

      const { offsetLeft, offsetTop } = this.$el;
      const x = Math.round(clientX - offsetLeft - this.halfSize);
      const y = Math.round(clientY - offsetTop - this.halfSize);
      this.updatePosition(x, y);
    },
    handleRelease() {
      // console.log("Mouse/Touch Up")
      this.$emit("touchFinish")
      this.isMouseDown = false; 
      if (this.isCircular) return

      // Delay para evitar updatear durante el cooldown
      const cooldowntime = this.$parent.EmitCooldownTime
      if(cooldowntime) {
        setTimeout(() => { this.updatePosition(0, 0)}, cooldowntime * 1.2)
      } else {
        this.updatePosition(0, 0);
      }
      
    },
    updatePosition(x, y) {
    
      this.radians = Math.atan2(y, x);
      const angle = Math.round((this.radians * 180) / Math.PI, 4);

      this.angle = angle + (angle > 180 ? -180 : 90);

      if (this.isCircular) {

        this.x = Math.cos(this.radians) * this.offset;
        this.y = Math.sin(this.radians) * this.offset;

      } else {
        this.speed = Math.min(Math.round(Math.sqrt(Math.pow(y, 2) + Math.pow(x, 2))), this.offset);
        this.x = this.speed > this.offset ? Math.cos(this.radians) * this.offset : x;
        this.y = this.speed > this.offset ? Math.sin(this.radians) * this.offset : y;
      }

      this.emitAll();
    },
    buttonChange(button,pressed){
      this.$emit("buttonchange", {
        button: button-1,
        pressed: pressed
      })
    },
    emitAll(name = "leverchange") {
      this.angle = this.angle - 90; //Angle 0 = radians 0 = 15:00 horas
      this.$emit(name, {
        angle: this.angle,
        radians: this.radians,
        x: this.isCircular ? 0 : this.normalize(this.x, 0, 1),
        y: this.isCircular ? 0 : this.normalize(this.y, 0, 1),
        speed: this.normalize(this.speed, 0, this.halfSize),
      });
    },
    normalize(val, min, max) {
      // console.log(min,max)
      return (val - min) / (max - min);
    },
    resetJoystick(){
      this.size = this.$refs.joystick.offsetWidth;
      this.halfSize = this.size * 0.5;
      this.offset = this.size * 0.375;
      this.speed = 0;
      this.x  = 0;
      if(this.isCircular) {
        this.y = this.offset
      } else {
        this.y = 0;
      }
    }

  },
  mounted() {
    this.resetJoystick();
    window.addEventListener('resize', this.resetJoystick);

    this.emitAll();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resetJoystick);
  }
};
</script>

<style scoped src="./JoyStick.scss" lang="scss"></style>