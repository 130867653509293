<template>
  
  <div class="touchpad-wrapper" :class="{touched: isTouched}">

    <div class="cta px-5 py-4 has-text-centered">
      Posicionate sobre el controlador y recorrelo para interactuar con la experiencia
    </div>
    <div class="touchpad-container">
      <div 
      ref="touchpad"
      class="touchpad"
      :class="`${shape}-shape`"
      :style="style"
      @touchmove="handleMove"
      @touchstart="handleTouch"
      @touchend="handleRelease">
      <div
        v-if="isTouched"
        class="touch"
        :style="{ backgroundColor: 'var(--userColor)' }">
      </div>
    </div>

    <div class="jButtons" v-if="buttons > 1">
      <div v-for="n in buttons"
        :key="`btn-${n}`"
        class="button"
        @touchstart="buttonChange(n,true)"
        @touchend="buttonChange(n,false)">
        {{['A','B','C'][n-1]}}
      </div>
    </div>
    </div>
   
  </div>
</template>


<script>
// TODO: XY normalizar 
export default {
  props: {
    centerColor: {
      type: String,
      default: '#fafafa'
    },
    buttons: {
      type: Number,
      default: 0
    },
    shape: {
      type: String,
      default: 'square'
    }
  },
  data() {
    return {
      x: 0,
      y: 0,
      angle: 180,
      ball: 0.15,
      speed: 0,
      radians: 0,
      size: 400,
      halfSize: 200,
      offset: 0,
      offsetLeft: 0,
      offsetTop: 0,
      isTouched: false,
    };
  },

  computed: {
    //Para pasar variables al css, deben asignarse en el estilo inline 
    style() {
      return {
        "--size": `${this.ball*100}%`,
        "--speed": `${this.speed}px`, 
        "--offcenter":`${this.speed}px`,
        "--angle": `calc(${this.angle}deg - 90deg)`,
        '--centerColor': this.centerColor,
        "--x": `${Math.round(this.x*this.halfSize*(1-this.ball))}px`,
        "--y": `${Math.round(this.y*this.halfSize*(1-this.ball))}px`,
      };
    }
  },

  methods: {
    handleTouch({ touches: [touch] }) {
      // console.log("Mouse/Touch Down")
      this.$emit("touchStart")
      this.isTouched = true
      this.handleMove({ touches: [touch] })
    },
    handleMove({ touches: [touch] }) {
      if(!this.isTouched) return
      // console.log(touch)
      const { clientX, clientY } = touch
      const x = Math.round(clientX - this.offsetLeft - this.halfSize)
      const y = Math.round(clientY - this.offsetTop - this.halfSize)
      this.updatePosition(x, y)
    },
    handleRelease() {
      this.$emit("touchRelease")
      this.isTouched = false
      
    },
    updatePosition(x, y) {


      if(this.shape == 'circle'){
        this.radians = Math.atan2(y, x)

        this.speed = Math.min(Math.hypot(x, y), this.offset)

        const angle = Math.round((this.radians * 180) / Math.PI, 4)
        this.angle = angle + (angle > 180 ? -180 : 90)

        const factor = this.speed > this.offset ? this.offset / this.speed : 1
        
        this.x = factor * x
        this.y = factor * y


      } else {

        const width = this.halfSize * (1 - this.ball)
        this.x = Math.min(Math.max(x / width,-1), 1)
        this.y = Math.min(Math.max(y / width,-1), 1)

      }

      this.emitAll()
    },
    buttonChange(button,pressed){

      this.$emit("buttonchange", {
        button: button-1,
        pressed: pressed
      })
    },
    emitAll(name = "leverchange") {

      this.angle = this.angle - 90; //Angle 0 = radians 0 = 15:00 horas

      this.$emit(name, {
        angle: this.angle,
        radians: this.radians,
        x: this.x,
        y: this.y,
        speed: this.normalize(this.speed, 0, this.halfSize * (1 - this.ball) ),
      })

    },
    normalize(val, min, max) {
      // console.log(min,max)
      return (val - min) / (max - min)
    },
    resetTouchpad(){
      const touchEl = this.$refs.touchpad
      
      this.size = touchEl.offsetWidth
      this.offsetLeft = touchEl.getBoundingClientRect().x + window.scrollX
      this.offsetTop = touchEl.getBoundingClientRect().y + window.scrollY
      this.halfSize = this.size * 0.5
      this.offset = this.size * (1 - this.ball) * 0.5
      this.speed = 0
      this.x  = 0
      this.y = 0
    }

  },
  mounted() {
    this.resetTouchpad()
    window.addEventListener('resize', this.resetTouchpad)

    this.emitAll()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resetTouchpad)
  }
};
</script>

<style scoped src="./TouchPad.scss" lang="scss"></style>